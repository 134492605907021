<template>
  <div :class="['userItem', { selected: selected }]" @click="choseSession">
    <div class="avatar-wrapper">
      <img class="userItemAvtar" :src="userInfo.avtar ??
        'https://qcloudimg.tencent-cloud.cn/raw/9c6b6806f88ee33b3685f0435fe9a8b3.png'
        " />
      <div v-if="
        type == 'serving' && userInfo.unreadCount && userInfo.unreadCount != 0
      " class="dot">
        {{ userInfo.unreadCount }}
      </div>
    </div>
    <div style="flex: 1; margin-left: 10px">
      <div class="userItemUserName" :style="{ color: selected ? 'white' : '' }">
        {{ getSessionUserShowName(userInfo) }}
      </div>
      <div v-if="type == 'serving' && userInfo?.lastMessage" class="userItemLastMessage"
        :style="{ color: selected ? 'white' : '' }">
        {{ formateLastMessage(userInfo?.lastMessage) }}
      </div>
      <div v-if="type == 'serving' && !userInfo?.lastMessage" class="userItemLastMessage"
        :style="{ color: selected ? 'white' : '' }"></div>
      <div v-if="type == 'ending'" class="userItemLastMessage" :style="{ color: selected ? 'white' : '' }">
        已结束
      </div>
    </div>
    <el-button v-if="type == 'queuing'" type="primary" @click="acceptSession">{{ timeout && timeout != 0 ? "(" + timeout
      + ")" : "" }}接入</el-button>
    <div v-if="type == 'serving' && userInfo?.lastMessage" class="userItemConncetTime"
      :style="{ color: selected ? 'white' : '' }">
      {{ timeDifference }}
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import {
  formateLastMessage,
  getSessionUserShowName,
} from "../../../../utils/index";

export default defineComponent({
  name: "UserItem",
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      userInfo: {},
      type: "",
      timeDifference: "",
      timeout: 0,
    });
    const tcccsdk = inject("tcccsdk");
    const store = useStore();
    const selectedSession = computed(() => store.state.session.selectedSession);
    let timer; // 用于存储计时器的 ID
    let timeoutTimer; //
    watchEffect(() => {
      data.userInfo = props.userInfo;
      data.type = props.type;
      data.tcccSdk = tcccsdk.value;
      data.timeout = props.userInfo.timeout;
    });

    const choseSession = async () => {
      if (data.type == "queuing") {
        return;
      }
      if (selectedSession?.value?.sessionId === data.userInfo.sessionId) {
        return;
      }
      if (data.type == "serving") {
        await data.tcccSdk.Chat.setMessageRead({
          sessionId: data.userInfo.sessionId,
        });
        store.commit("session/updateOuterSessionUnreadCount", {
          sessionId: data.userInfo.sessionId,
          count: 0,
        });
      }
      store.commit("message/initMessageList", []);
      store.commit("session/setCurrentSession", data.userInfo);
    };
    const acceptSession = async () => {
      await data.tcccSdk.Chat.seatInChat({
        sessionId: data.userInfo.sessionId,
      });
      store.commit("session/acceptOuterSessionList", data.userInfo);
    };
    const timeDifference = () => {
      if (data.type == "serving" && data?.userInfo?.lastMessage) {
        const diff = Math.abs(
          new Date().getTime() - data?.userInfo?.lastMessage?.time * 1000
        );
        // 计算不同时间单位的差值
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        // 根据时间差返回适当的字符串
        if (days > 0) {
          data.timeDifference = `${days} 天前`;
        } else if (hours > 0) {
          data.timeDifference = `${hours} 小时前`;
        } else if (minutes > 0) {
          data.timeDifference = `${minutes} 分钟前`;
        } else {
          data.timeDifference = `${seconds} 秒前`;
          // data.timeDifference= `刚刚`;
        }
      }
    };

    const timeoutDown = () => {
      if (data.timeout != null)
        if (data.timeout > 0) {
          data.timeout = data.timeout - 1;
        }
    };
    onMounted(() => {
      timer = window.setInterval(timeDifference, 1000);
      timeoutTimer = window.setInterval(timeoutDown, 1000);
    });
    onUnmounted(() => {
      data.timeDifference = "";
      data.timeout = 0;
      if (timer !== undefined) {
        window.clearInterval(timer);
      }
      if (timeoutTimer !== undefined) {
        window.clearInterval(timeoutTimer);
      }
    });

    return {
      ...toRefs(data),
      choseSession,
      acceptSession,
      formateLastMessage,
      getSessionUserShowName,
      selected: computed(
        () => selectedSession?.value?.sessionId == data.userInfo.sessionId
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.selected {
  background: linear-gradient(90deg, #006eff 0%, #4696ff 100%);
  border-bottom: 1px solid #f7f7f7;
}

.userItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0 10px 10px;
  border-radius: 2px;
  width: 220px;

  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .dot {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    font-size: 12px;
    color: white;
    line-height: 20px;
    text-align: center;
  }

  .userItemAvtar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
  }

  .userItemUserName {
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC-Medium !important;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .userItemLastMessage {
    color: #8a8f97;
    overflow: hidden;
    font-size: 12px;
    margin-top: 3px;
    max-height: 17px;
    min-height: 17px;
    font-family: PingFangSC-Regular !important;
    line-height: 17px;
    height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
  }

  .userItemConncetTime {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    text-align: right;
    font-family: PingFangSC-Regular !important;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    align-self: flex-end;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
</style>
