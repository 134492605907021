<template>
  <el-tabs :stretch="true" v-model="activeName" class="demo-tabs">
    <el-tab-pane label="外部会话" name="Outer">
      <OuterConversationList></OuterConversationList>
    </el-tab-pane>
    <!-- <el-tab-pane label="内部会话" name="Inner">
      <InnerConversationList></InnerConversationList>
    </el-tab-pane> -->
  </el-tabs>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import OuterConversationList from "./outerConversationList/index.vue";
// import InnerConversationList from "./innerConversationList/index.vue";   InnerConversationList,

export default defineComponent({
  name: "ConversationList",
  components: {
    OuterConversationList,
 
  },
  setup() {
    const data = reactive({
      activeName: "Outer",
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style lang="scss" scoped></style>
