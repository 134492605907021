<template>
  <div class="chatContainer" v-if="activeSession && !isTransfer">
    <Header :activeSession="activeSession"></Header>
    <MessageList :activeSession="activeSession"></MessageList>
    <MessageSender
      v-if="
        activeSession.status == SESSION_STATUS.IN_PROGRESS ||
        activeSession.sessionId?.startsWith('C2C')
      "
      :activeSession="activeSession"
      @clickTransfer="clickTransfer"
    >
    </MessageSender>
    <div v-else class="messageSenderContainerButton" >
      <el-button type="primary" @click="submitForm">发起会话</el-button>
    </div>
  </div>
  <div class="transferContainer" v-if="activeSession && isTransfer">
    <el-button
      style="margin-left: 10px"
      type="primary"
      @click="isTransfer = false"
      >返回</el-button
    >
    <Transfer
      :activeSession="activeSession"
      @transferEnd="isTransfer = false"
    ></Transfer>
  </div>
  <div class="emptyChatContainer" v-show="!activeSession">
    <img
      src="https://static.tccc.qcloud.com/static/media/empty-msg.fb3ca484250db4b3ccfde6614cbc1c0a.svg"
    />
    <div>暂无消息</div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, watch, watchEffect, inject } from "vue";
import { useStore } from "vuex";
import Header from "./Header/index.vue";
import MessageSender from "./MessageSender/index.vue";
import MessageList from "./MessageList/index.vue";
import { SESSION_STATUS } from "../../constants";
import Transfer from "../Transfer/index.vue";

export default defineComponent({
  name: "ChatComponent",
  components: {
    Header,
    MessageSender,
    MessageList,
    Transfer,
  },
  setup() {
    const data = reactive({
      isTransfer: false,
      tcccSdk: null,
      userId: null,
    });
    const store = useStore();
    const activeSession = computed(() => store.state.session.selectedSession);
    const clickTransfer = () => {
      data.isTransfer = true;
    };
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.tcccSdk = tcccsdk.value;
    });
    watchEffect(() => {
      try{
        data.userId = activeSession.value.userId
      }catch(e){  console.log(e)
        
      }
    });
    watch(
      () => activeSession,
      (newValue, oldValue) => {
        if (newValue?.sessionId !== oldValue?.sessionId) {
          data.isTransfer = false;
        }
      }
    );
    const submitForm = async () => {
      if (data.tcccSdk) {
        await data.tcccSdk.Chat.startFollowUpConversation({
          channelAgentID: "@customer_service_account",
          ClientUserID: data.userId,
        });
      }
    };
    return {
      ...toRefs(data),
      SESSION_STATUS,
      activeSession,
      clickTransfer,
      submitForm,
    };
  },
});
</script>

<style lang="scss" scoped>
.chatContainer {
  display: flex;
  max-width: 1100px;
  // width: 800px;
  flex: 1;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
  height: calc(100vh - 56px) ;
  flex-direction: column;
  justify-content: space-between;
}

.transferContainer {
  padding-top: 10px;
  max-width: 800px;
  width: 800px;
  height: 100vh;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
}

.emptyChatContainer {
  display: flex;
  max-width: 1100px;
  // width: 800px;
  flex: 1;
  border: 1px solid #e5e5e5;
  border-width: 0 1px 0 1px;
  height: calc(100vh - 56px) ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.messageSenderContainerButton{
  height: 200px;
  max-height: 200px;
  width: 100%;
  display: flex;justify-content: center;align-items: center;
}
</style>
