<template>
  <div class="messageActionsContainer" :style="`
    right: ${message.flow === 'in' && message?.from !== 'administrator' ? 'auto' : '70px'};
    left: ${message.flow === 'in' && message?.from !== 'administrator' ? '70px' : 'auto'};
    `">
    <div class="action" v-if="message.type === types.MSG_TEXT" @click="copy">
      复制 <i class="icon-msg-copy"></i>
    </div>
    <div class="action" @click="quote">引用 <i class="icon-msg-quote"></i></div>
    <div class="action"  v-if="message.type === types.MSG_IMAGE"  @click="download">
      下载 <i class="icon-msg-xia"></i></div>
    <div class="action"  v-if="message.type === types.MSG_VIDEO"  @click="videodownload">
        下载 <i class="icon-msg-xia"></i></div>
    <div class="action" v-if="message.flow !== 'in'" @click="revoke">
      撤回 <i class="icon-msg-revoke"></i>
    </div>
  </div>
</template>

<script>
import TIM from "@tencentcloud/chat";
import { defineComponent, inject, reactive, toRefs, watchEffect } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";


export default defineComponent({
  name: "MessageActions",
  props: {
    message: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      message: {},
      types: TIM.TYPES,
    });
    const store = useStore();
    const tcccsdk = inject("tcccsdk");
    const copy = () => {
      navigator.clipboard.writeText(data.message.payload.text);
    };
    const quote = () => {
      store.commit("message/updateQuoteMessage", data.message);
    };
    const download = ()=>{
      console.log(data.message)
      window.location.href = data.message.payload.imageInfoArray[0].url
    };
    const videodownload = ()=>{
      console.log(data.message)
      window.location.href = data.message.payload.videoUrl
    };
    const revoke = async () => {
      if (data.tcccSdk) {
        try {
          await data.tcccSdk.Chat.revokeMessage(data.message);
          store.commit("message/updateRevokeMessage", data.message.ID);
          store.commit("session/updateSessionsLastMessage", {
            type: "revokeMessage",
            message: data.message,
          });
        } catch (e) {
          ElMessage.error("撤回时间超过2分钟 不能撤回");
        }

      }
    };
    watchEffect(() => {
      data.message = props.message;
      data.tcccSdk = tcccsdk.value;
    });
    return {
      ...toRefs(data),
      copy,
      quote,
      revoke,
      download,videodownload
    };
  },
});
</script>

<style lang="scss" scoped>
.messageActionsContainer {
  border-radius: 8px;
  padding: 6px 0;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  width: fit-content;
  background: white;
  position: absolute;
  z-index: 5;

  .action {
    padding: 4px 6px;
    font-size: 14px;
    width: 100px;
    display: flex;
    justify-content: space-between;
  }

  .action:hover {
    background: #abcffc;
  }

  .icon-msg-quote {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-qnote.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-xia {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-xia.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-copy {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-copy.svg") no-repeat;
    background-size: contain;
  }

  .icon-msg-revoke {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 2px;
    background: url("../../../../assets/icon/msg-revoke.svg") no-repeat;
    background-size: contain;
  }
}
</style>
