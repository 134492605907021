<template>
  <div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script >
export default {
  name: "App",
  components: {},
  setup(){
    window.document.title  = "客户通"
  }
};
</script>

<style>
</style>
