<!-- 快捷回复 -->
<template>
    <div class="fastreply-bk">
        <div class="fastreply-search">
            <!-- <el-input class="search-ipt" v-model="input" placeholder="请输入内容"></el-input>
            <el-button type="primary">确定</el-button> -->
            <el-button type="primary" @click="handleEdit(0, null)">添加</el-button>
        </div>
    </div>
    <div class="fastreply-tabs">
        <!-- <div style="margin-bottom: 4px">
            <el-button size="small" @click="modifyTab(null)">添加分组</el-button>
            <el-button size="small" @click="modifyTab(activeName)">修改分组</el-button>
        </div> -->
        <!-- <el-tabs :stretch="true" v-model="activeName" type="border-card">
            <el-tab-pane v-for="item in typeList" :key="item.type_id" :label="item.type_title" :name="item.type_id"> -->
        <el-table :data="tableData" :height="716" style="width: 100%">
            <el-table-column prop="type_title" label="分组" width="100" column-key="type_id" :filters="List"
                :filter-method="filterHandler" />
            <el-table-column prop="mess_title" label="标题" width="100" />
            <el-table-column prop="mess_info" show-overflow-tooltip  label="内容" width="180" />
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                        修改
                    </el-button>
                    <el-button type="primary" size="small" @click="sending(scope.$index, scope.row)">
                        发送
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 4px;" background layout="prev, pager, next" @change="changePagination"
            v-model:current-page="page" :total="total" />
    </div>

    <el-dialog title="添加快捷回复" v-model="addVisible" width="40%">
        <el-form :model="Form" label-width="80px">
            <el-form-item label="分组">
                <el-select v-model="type_id" placeholder="请选择分组" @change="selecttype">
                    <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_title"
                        :value="item.type_id" />
                </el-select>
                <el-button type="primary" style="margin-left: 20px;" @click="modifyTab(0)">添加分组</el-button>
                <el-button style="margin-left: 20px;" @click="modifyTab(type_id)">修改分组</el-button>
            </el-form-item>
            <el-form-item label="标题">
                <el-input placeholder="请输出标题" v-model="mess_title"></el-input>

            </el-form-item>
            <el-form-item label="内容">
                <el-input type="textarea" placeholder="请输出内容" v-model="mess_info"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="addVisible = false">取消</el-button>
                <el-button type="primary" @click="setQuickMessCreate"> 确定 </el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog title="添加/修改分组" v-model="quickShow" width="40%">
        <el-form :model="Form" label-width="80px">
            <el-form-item label="分组">
                <el-input placeholder="请输入分组" v-model="typeTitles"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="quickShow = false">取消</el-button>
                <el-button type="primary" @click="setQuickTypeCreate"> 确定 </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import { quickTypeLists, quickTypeCreate, quickMessLists, quickMessCreate } from "../../../utils/api"
const store = useStore();
const addVisible = ref(false)
const quickShow = ref(false)


const sdk = ref()
// const activeName = ref(0)
const Form = reactive({
    type_id: null,
    type_title: null,
    mess_id: 0,
    mess_info: null,
    typeList: [{ type_id: 0, type_title: "全部" }],
    List: [],
    tableData: [],
    mess_title: null,
    typeIds: null,
    typeTitles: null,
    page: 1,
    total: 0,
    ids: 0,
})
const { type_id, type_title, ids, mess_id, mess_info, mess_title, List, typeList, typeIds, typeTitles, tableData, page, total } = toRefs(Form) // type_id,type_title,

// 发送信息
const sending = (index, item) => {
    console.log(index, item)
    store.commit("message/updateSending", item.mess_info);

}

// 修改当前 文件 
const handleEdit = (index, item) => {
    console.log(index, item)
    addVisible.value = true
    if (item) {
        try {
            type_id.value = item.type_id
            type_title.value = item.type_title
            mess_title.value = item.mess_title
            mess_id.value = item.mess_id
            mess_info.value = item.mess_info
        } catch (e) {
            console.log(e)
        }

    } else {
        type_id.value = null
        type_title.value = null
        mess_title.value = null
        mess_id.value = null
        mess_info.value = null
    }
}

// 修改分组
const modifyTab = (event) => {
    quickShow.value = true
    if (event) {
        typeList.value.map((item) => {
            if (item.type_id == event) {
                typeIds.value = item.type_id
                typeTitles.value = item.type_title
            }
        })
    } else {
        typeIds.value = 0
        typeTitles.value = ""
    }
}
// 切换 分组
const selecttype = (event) => {
    typeList.value.map((item) => {
        if (item.type_id == event) {
            type_id.value = item.type_id
            type_title.value = item.type_title
        }
    })
    console.log(event)
}
const changePagination = () => {
    console.log(page.value)
    // setQuickMessLists()
}

// 筛选 
const filterHandler = (value, row, column) => {
    console.log(value, row, column)
    const property = column['property']
    return row[property] === value
    // setQuickMessLists()
}

// 获取uid 
const getUserID = () => {
    let sdkconfig = JSON.parse(sessionStorage.getItem("sdkconfig"))
    sdk.value = sdkconfig
    console.log()
    setQuickTypeLists()
    setQuickMessLists()
}
// 查询快捷回复分类列表分页
const setQuickTypeLists = async () => {
    let params = {
        userId: sdk.value.userId,
        page: 1,
        limit: 100,
    }
    let res = await quickTypeLists(params)
    if (res.code == 0) {
        typeList.value = res.data.data
        List.value = res.data.data.map((item) => {
            return {
                text: item.type_title,
                value: item.type_title
            }
        })
        // typeList.value.push(...res.data.data)
    }
    console.log(res)
}
// 添加更新快捷回复分类
const setQuickTypeCreate = async () => {
    if (!typeTitles.value) {
        ElMessage.error('请填写分组')
        return
    }
    let params = {
        userId: sdk.value.userId,
        type_id: typeIds.value,
        type_title: typeTitles.value,
        pid: 0,
    }
    let res = await quickTypeCreate(params)
    quickShow.value = false
    setQuickTypeLists()
    if (res.code != 0) {
        ElMessage.error(res.msg)
    } else if (typeIds.value == 0) {
        type_id.value = ""
        type_title.value = ""
    }
    console.log(res)
}
// 查询快捷回复列表分页
const setQuickMessLists = async () => {
    let params = {
        userId: sdk.value.userId,
        page: 1,
        limit: 16,
        type_id: ids.value,
    }
    let res = await quickMessLists(params)
    tableData.value = res.data.data
    total.value = res.data.total
    console.log(res)
}
// 添加更新快捷回复
const setQuickMessCreate = async () => {
    if (!type_id.value) {
        ElMessage.error('请选择分组')
        return
    }
    if (!mess_info.value) {
        ElMessage.error('请填写内容')
        return
    }
    let params = {
        userId: sdk.value.userId,
        type_id: type_id.value,       // 分类id：0添加，大于0修改
        mess_title: mess_title.value,    //  标题  分类标题
        mess_id: mess_id.value,
        mess_info: mess_info.value,
        pid: 0   //  父类id 
    }
    let res = await quickMessCreate(params)
    addVisible.value = false
    setQuickMessLists()
    if (res.code != 0) {
        ElMessage.error(res.msg)
    }
    console.log(res)
}
onMounted(() => {
    getUserID()
});

</script>

<style lang="scss" scoped>
.fastreply-bk {
    width: 100%;
    height: 100%;

    .fastreply-search {
        display: flex;

        .search-ipt {
            margin-right: 20px;
        }
    }
}

.fastreply-tabs {
    margin: 16px 0 10px;
}
.mess_info{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* 可选，当内容超出一行时，用省略号表示 */
}


::v-deep .el-form-item__content {
    display: flex;
    flex-wrap: nowrap;
}
</style>