import { SESSION_STATUS } from "../constants/index";

const state = {
  // 选中的会话
  selectedSession: undefined,
  // 外部会话列表
  outerSessionList: [],
  // 内部会话列表
  innerSessionList: [],
  audioUrl:"",
};

const getters = {
  // 等待接入的会话列表
  queuingList: (state) =>
    state.outerSessionList?.filter(
      (session) => session?.status === SESSION_STATUS.RINGING
    ),
  // 正在服务的会话列表
  servingList: (state) =>
    state.outerSessionList?.filter(
      (session) => session?.status === SESSION_STATUS.IN_PROGRESS
    ),
  // 已经结束的会话列表
  endedList: (state) =>
    state.outerSessionList?.filter(
      (session) => session?.status === SESSION_STATUS.FINISHED
    ),
};

const mutations = {
  // 选择会话设置当前的会话属性
  setCurrentSession(state, session) {
    state.selectedSession = session;
    // 清空选中会话的未读数
    if (session) {
      state.outerSessionList = state.outerSessionList.map((item) => {
        if (item.sessionId === session?.sessionId && item?.unreadCount) {
          item.unreadCount = 0;
        }
        return item;
      });
    }
  },
  // 初始化外部会话列表
  initOuterSessionList(state, sessions) {
    state.outerSessionList = sessions;
    state.outerSessionList = state.outerSessionList.map((item) => {
      item.unreadCount = 0;
      return item;
    });
  },
  // callin事件插入外部会话
  insertOuterSessionList(state, session) {
    state.outerSessionList.push(session);
  },
  // 接入外部会话
  acceptOuterSessionList(state, session) {
    state.outerSessionList = state.outerSessionList.map((item) => {
      if (item.sessionId === session?.sessionId) {
        item.status = SESSION_STATUS.IN_PROGRESS;
        item.unreadCount = 0;
      }
      return item;
    });
  },
  // 结束外部会话
  endOuterSessionList(state, session) {
    state.outerSessionList = state.outerSessionList.map((item) => {
      if (item.sessionId === session?.sessionId) {
        item.status = SESSION_STATUS.FINISHED;
      }
      return item;
    });
  },
  // 处理异常结束的外部会话例如超时未接、用户超时回复
  handleUnnormalSessionEnd(state, session) {
    state.outerSessionList = state.outerSessionList.map((item) => {
      if (item.sessionId === session?.sessionId) {
        item.status = SESSION_STATUS.FINISHED;
      }
      return item;
    });
    if (state.selectedSession?.sessionId == session?.sessionId) {
      state.selectedSession = undefined;
    }
  },
  // 完成内外部会话
  completeOuterSessionList(state, session) {
    state.outerSessionList = state.outerSessionList.filter((item) => {
      return item?.sessionId !== session?.sessionId;
    });
  },
  // 更新外部会话的未读数，用于清除或直接设置未读数
  updateOuterSessionUnreadCount(state, data) {
    state.outerSessionList = state.outerSessionList.map((item) => {
      if (item?.sessionId === data?.sessionId) {
        item.unreadCount = data.count;
      }
      return item;
    });
  },
  // 增加外部会话的未读数
  increaseOuterSessionUnreadCount(state, message) {
    // 如果当前选择了的会话收到消息就不增加未读数
    if (message?.sessionId === state?.selectedSession?.sessionId) {
      return;
    }
    state.outerSessionList = state.outerSessionList.map((item) => {
      if (
        item?.sessionId === message.sessionId &&
        item?.unreadCount != undefined
      ) {
        item.unreadCount += 1;
      }
      // 防止没有设置unreadCount导致报错
      if (
        item?.sessionId === message.sessionId &&
        item?.unreadCount == undefined
      ) {
        item.unreadCount = 1;
      }
      return item;
    });
  },

  // 初始化内部会话列表
  initInnerSessionList(state, sessions) {
    state.innerSessionList = sessions;
  },
  // 收到内部消息时，更新内部会话列表
  updateInnerSession(state, session) {
    if (
      state.innerSessionList.some(
        (item) => item?.sessionId === session?.sessionId
      )
    ) {
      state.innerSessionList.push(session);
    }
  },

  // 收到消息更新会话的lastmessage
  updateSessionsLastMessage(state, option) {
    if (option?.type == "receiveMessage") {
      state.outerSessionList = state.outerSessionList.map((item) => {
        if (item.sessionId === option?.message?.sessionId) {
          item.lastMessage = option?.message;
        }
        return item;
      });
      state.innerSessionList = state.innerSessionList.map((item) => {
        if (item.sessionId === option?.message?.sessionId) {
          item.lastMessage = option?.message;
        }
        return item;
      });
    }
    if (option?.type == "revokeMessage") {
      state.outerSessionList = state.outerSessionList.map((item) => {
        if (
          item.sessionId === option?.message?.sessionId &&
          option?.message?.ID == item?.lastMessage?.ID
        ) {
          item.lastMessage.isRevoked = true;
        }
        return item;
      });
      state.innerSessionList = state.innerSessionList.map((item) => {
        if (
          item.sessionId === option.message.sessionId &&
          option?.message?.ID == item?.lastMessage?.ID
        ) {
          item.lastMessage.isRevoked = true;
        }
        return item;
      });
    }
  },

  getAudioUrl(state, option){
    state.audioUrl = option
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
