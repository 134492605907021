<!-- 快捷回复 -->
<template>
    <div class="fastreply-tabs">
        <el-table :data="seatList" :height="716" style="width: 100%">
            <el-table-column prop="Nick" show-overflow-tooltip label="昵称" width="100" />
            <el-table-column prop="Email" show-overflow-tooltip label="邮箱" width="180" />
            <el-table-column label="状态">
                <template #default="scope">
                    <!-- {{  scope.row.Status }} -->
                    <el-tag v-if="scope.row.Status == 'free' || scope.row.Status == 'ready'">空闲</el-tag>
                    <el-tag v-else-if="scope.row.Status == 'notReady' || scope.row.Status == 'busy'"
                        type="warning">忙碌</el-tag>
                    <el-tag v-else type="info">离线</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { defineComponent, inject, watch, reactive, toRefs } from "vue"; //  ref, , toRefs, 
import { getAllFreeSeatAndSkillGroupList } from "../../../utils/restApis";

// import { ElMessage } from 'element-plus'
export default defineComponent({
    name: "kefu",
    props: {
        activeName: {
            type: String,
            default: () => null,
        },
    },
    setup(props) {
        const data = reactive({
            tcccSdk: null,
            seatList: [],
        });
        const tcccsdk = inject("tcccsdk");
        watch(() => props.activeName, async (newValue) => {
            if (newValue) {
                console.log(newValue)
                data.tcccSdk = tcccsdk.value;
                const initSDKRes = JSON.parse(sessionStorage.getItem("sdkconfig"))
                let getdata = {
                    SdkAppId: initSDKRes.sdkAppId,
                }
                if (data.tcccSdk) {
                    const freeData = await getAllFreeSeatAndSkillGroupList(getdata);
                    console.log("freeData", freeData)
                    data.seatList = freeData.allFreeSeatRes;
                }
            }
        });
        return {
            ...toRefs(data),
        };
    }
});
</script>

<style lang="scss" scoped>
.fastreply-bk {
    width: 100%;
    height: 100%;

    .fastreply-search {
        display: flex;

        .search-ipt {
            margin-right: 20px;
        }
    }
}

.fastreply-tabs {
    margin: 16px 0 10px;
}

.mess_info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 可选，当内容超出一行时，用省略号表示 */
}


::v-deep .el-form-item__content {
    display: flex;
    flex-wrap: nowrap;
}
</style>