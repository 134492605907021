import { getAllFreeSeat, DescribeStaffInfoList } from "./api";

export const getAllSkillGroup = async () => {
  const allSkillGroupRes = [];
  let option = {
    PageSize: 100,
    PageNumber: 0,
  };
  let hasMore = true; // 用于控制循环的布尔变量

  while (hasMore) {
    try {
      const response = await fetch("/getSkillGroupList", {
        method: "POST", // 使用 POST 方法
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(option),
      });

      const data = await response.json();

      allSkillGroupRes.push(...data.skillGroupList);

      // 检查是否已经获取到所有的技能组
      if (allSkillGroupRes.length >= data.total) {
        hasMore = false; // 终止循环
      } else {
        // 增加 PageNumber 以获取下一页的数据
        option.PageNumber += 1;
      }
    } catch (error) {
      console.error("Error fetching skill group list:", error);
      hasMore = false; // 终止循环
    }
  }

  return allSkillGroupRes;
};

export const getSelfSkillGroup = async () => {
  let selfSkillGroupRes = [];
  const response = await fetch("/getSelfSkillGroup");
  const data = await response.json();
  selfSkillGroupRes = data?.staffList[0];
  return selfSkillGroupRes?.SkillGroupList ?? [];
};

export const getAllFreeSeatAndSkillGroupList = async (data) => {
  let allFreeSeatRes = [];
  let allFreeSkillGroupRes = [];
  const newMap = new Map();
  const resSeats = await getAllFreeSeat(data);
  const sdkconfig = JSON.parse(sessionStorage.getItem("sdkconfig"));
  const seatResponse = resSeats.data.data;
  const seatData = seatResponse;

  allFreeSeatRes =
    seatData?.Metrics.map((item) => {
      return {
        userId: item.Email,
        staffName: item.Email,
        ...item,
      };
    }) ?? [];

  const janeObj = allFreeSeatRes.findIndex(
    (obj) => obj.userId === sdkconfig.userId);
  allFreeSeatRes.splice(janeObj, 1);
  for (let i = 0; i < allFreeSeatRes.length; i++) {
    const skillGroupResponse = await DescribeStaffInfoList({ StaffMail: allFreeSeatRes[i].userId })
    const skillGroupData = skillGroupResponse.data.StaffList[0];
    try {
      allFreeSeatRes[i].Nick = skillGroupData.Nick;
      for (let j = 0; j < skillGroupData.SkillGroupList.length; j++) {
        if (newMap.has(skillGroupData.skillGroupList[j].SkillGroupId)) {
          const freeStaffCount =
            newMap.get(skillGroupData.skillGroupList[j].SkillGroupId)
              .freeStaffCount + 1;
          newMap.set(skillGroupData.skillGroupList[j].SkillGroupId, {
            skillGroupId: skillGroupData.skillGroupList[j].SkillGroupId,
            skillGroupName: skillGroupData.skillGroupList[j].SkillGroupName,
            freeStaffCount,
          });
        } else {
          newMap.set(skillGroupData.skillGroupList[j].SkillGroupId, {
            skillGroupId: skillGroupData.skillGroupList[j].SkillGroupId,
            skillGroupName: skillGroupData.skillGroupList[j].SkillGroupName,
            freeStaffCount: 1,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  for (const [, value] of newMap) {
    allFreeSkillGroupRes.push({
      skillGroupId: value.skillGroupId,
      skillGroupName: value.skillGroupName,
      freeStaffCount: value.freeStaffCount,
    });
  }
  return { allFreeSeatRes, allFreeSkillGroupRes };
};