import http from "./http";
import https from "./https";

// 登录
export function LoginApi(data) {
  return http({
   	url: "/txy/kht/kf_login",
    method: "post",
    // responseType: 'blob',
    data: data,
  })
}


export function getSelfSkillGroup(data){
  return http({
    url: "/txy/Kht/getSeats",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};

export function getSeats(data){
  return http({
    url: "/txy/Kht/getSeats",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


export function getAllFreeSeat(data){
  return http({
    url: "txy/Kht/getSeatsstaff",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


export function DescribeStaffInfoList(data){
  return http({
    url: "/txy/Kht/getSeats",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};

// 用户CRM详情 
export function getUserInfo(data){
  return http({
    url: "/txy/kht/getUserInfo",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


// 修改用户crm数据
export function editUser(data){
  return http({
    url: "/txy/kht/editUser",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};



// 查询快捷回复列表分页  接口：https://kft.heatingpay.com/txy/kht/quick_mess_lists
//  参数：userId：客服邮箱   page：1页数   limit：10 每页数量   title： 标题   type_id：0 分类/组id 0全部查询
export function quickMessLists(data){
  return http({
    url: "/txy/kht/quick_mess_lists",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


// 添加更新快捷回复  接口：https://kft.heatingpay.com/txy/kht/quick_mess_create
//  参数： userId：客服邮箱 
//        type_id：0 分类id 0是添加，大于0 修改
//        type_title：标题  分类标题
//        mess_info： 分类内容 
//        pid：0 父类id 
export function quickMessCreate(data){
  return http({
    url: "/txy/kht/quick_mess_create",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


// 查询快捷回复分类列表分页
// 接口：https://kft.heatingpay.com/txy/kht/quick_type_lists
// 参数：
// userId：客服邮箱
// page：1页数
// limit：10 每页数量
export function quickTypeLists(data){
  return http({
    url: "/txy/kht/quick_type_lists",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};


// 添加更新快捷回复分类
// 接口：https://kft.heatingpay.com/txy/kht/quick_type_create
// 参数：
// userId：客服邮箱
// type_id：0 分类id：0添加，大于0修改
// type_title：标题  分类标题
// pid：0 父类id 
export function quickTypeCreate(data){
  return http({
    url: "/txy/kht/quick_type_create",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};

// 切片上传
export function uploadFile(data){
  return https({
    url: "/online/Upload/assce",
    method: "post",
  //  responseType: 'blob',
   data: data,
   headers: {
    'Content-Type': 'multipart/form-data'
   // 其他默认请求头可以在这里配置
 }
   })
};

// 点击发送
export function uploadFileMerge(data){
  return http({
    url: "/txy/kht/senemail",
    method: "post",
   // responseType: 'blob',
   data: data,
   })
};