<!-- 快捷回复 -->
<template>
    <el-form ref="ruleFormRef" style="max-width: 600px" :model="ruleForm" status-icon :rules="rules" label-width="auto"
        class="demo-ruleForm">
        <el-form-item label="收件人" prop="email">
            <el-input v-model="ruleForm.email" autocomplete="off" />
        </el-form-item>
        <el-form-item label="主题" prop="title">
            <el-input v-model="ruleForm.title" autocomplete="off" />
        </el-form-item>
        <el-form-item label="正文" prop="body">
            <el-input v-model="ruleForm.body" type="textarea" autocomplete="off" />
        </el-form-item>
        <el-form-item label="上传附件">
            <div style="width: 100%;">
                <index :item="item" @changefile="changefile"></index>
            </div>
            <div v-for="(item, index) in filelist" :key="index" class="upload-for">
                <div class="upload-name">
                    <div class="name">{{ item.name }}</div>
                    <div class="cuo" @click="delRemove(index)">×</div>
                </div>
                <el-progress class="upload-progress" :text-inside="true" :stroke-width="14"
                    :percentage="item.progress" />
            </div>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)">确定发送</el-button>
        </el-form-item>
    </el-form>
    <div>

    </div>
</template>

<script setup>
// import { reactive, defineComponent, toRefs } from "vue";
import { reactive, ref } from 'vue'
import index from "./component.vue"
import { uploadFileMerge } from "../../../utils/api"
import { ElMessage } from 'element-plus'
const ruleFormRef = ref()

const validatePass = (rule, value, callback) => {
    var reg = /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/g
    if (value === '') {
        callback(new Error('请输入邮箱地址'))
    } else if (!reg.test(value)) {
        callback(new Error('请输入正确邮箱'))
    } else {
        if (ruleForm.checkPass !== '') {
            if (!ruleFormRef.value) return
            ruleFormRef.value.validateField('checkPass')
        }
        callback()
    }
}

const filelist = ref([])
const ruleForm = reactive({
    email: '',
    title: '',
    body: '',
    file: null,
})

const rules = reactive({
    email: [{ validator: validatePass, trigger: 'blur' }],
})

const changefile = (e) => {
    const janeObj = filelist.value.findIndex((obj) => obj.uid === e.uid);
    if (janeObj == -1) {
        filelist.value.push(e)
    } else {
        filelist.value.splice(janeObj, 1, e)
    }
    console.log(e, filelist.value, janeObj)
}
const delRemove = (e) => {
    // const janeObj = filelist.value.findIndex((obj) => obj.uid === e);

    filelist.value.splice(e, 1)
    // console.log(e, filelist.value,janeObj)
    // filelist.value.map((item,index)=>{
    //     item.index = index
    // })
}
const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate(async (valid) => {
        if (valid) {
            let show = true
            filelist.value.map((item) => {
                console.log(item)
                if (item.progress && item.progress != 100) {
                    show = false
                }
            })
            if (!show) {
                ElMessage({ message: '请等待文件上传完', type: 'error', plain: true, })
                return
            }


            // let arr = []
            let jsonarr = []
            filelist.value.map((item) => {
                if (item.url) {
                    // arr.push(item.url.slice(1))
                    jsonarr.push({"name":item.name,"url":item.url.slice(1)})
                }
            })
            // ruleForm.file = arr.join(",")
            let data = {
                email: ruleForm.email,
                // file: ruleForm.file,
                filejson: JSON.stringify(jsonarr) ,
            }
            
            if (ruleForm.title) { data.title = ruleForm.title }
            if (ruleForm.body) { data.body = ruleForm.body }
            await uploadFileMerge(data).then((res)=>{
                if (res.code == 0) {
                ElMessage({ message: '发送成功!', type: 'success', plain: true, })
                filelist.value = []
                ruleForm.email = ""
                ruleForm.title = ""
                ruleForm.body = ""
                ruleForm.file = null
            }
            }).catch((error)=>{
                console.log(error)
            })
        
        } else {
            console.log('error submit!')
        }
    })
}

</script>

<style lang="scss" scoped>
.upload-for {
    width: 100%;
    display: flex;
    flex-direction: column;

    .upload-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            overflow: hidden;
            /* 隐藏超出范围的内容 */
            text-overflow: ellipsis;
            /* 使用省略号 */

        }

        .cuo {
            cursor:pointer;
        }
    }
}
</style>