  import axios from 'axios';
  import { ElMessage } from 'element-plus'

  // 创建一个 axios 实例
  const http = axios.create({
    baseURL: "https://kft.heatingpay.com",
    timeout: 30000, // 请求超时时间
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',

      // 其他默认请求头可以在这里配置
    }
  });

 
// 添加请求拦截器
http.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		console.log(config)

		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)
 
// 添加响应拦截器
http.interceptors.response.use(
	function (response) {
		console.log(response)
		if(response.status === 200 && response.data.code != 0){
			ElMessage({
				message:  response.data.msg ,
				type: 'error',
			  })
		}
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		// 这个状态码是和后端约定的
		// const code = dataAxios.reset
		// console.log(code)
		return dataAxios
	},
	function (error) {
	
			ElMessage({
				message:  '当前网络错误! ',
				type: 'error',
			  })

		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		// console.log(error)
		return Promise.reject(error)
	}
)

  
  export default http;
  
  