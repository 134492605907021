<template>
  <div
    class="transferItem"
    :class="[selected ? 'selected' : '']"
    @click="selectTransferItem"
  >
    <div style="width: 25%;">      {{ showName }}    </div>
    <div style="width: 50%;">      {{ item.Email }}    </div>
    <div style="width: 25%;">      
      <el-tag v-if="item.Status == 'free' || item.Status == 'ready'" >空闲</el-tag>
      <el-tag v-else-if="item.Status == 'offline' || item.Status == 'rest'" type="info">离线</el-tag>
      <el-tag v-else-if="item.Status == 'notReady' || item.Status == 'busy'" type="warning">忙碌</el-tag>
    </div>
          <!-- 空闲 -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";

export default defineComponent({
  name: "ConversationList",
  props: {
    showName: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    freeStaffCount: {
      type: Number,
      default: () => 0,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, ctx) {
    const data = reactive({
      showName: "",
      type: "",
      freeStaffCount: 0,
      item: {},
      selected: false,
    });
    watchEffect(() => {
      data.showName = props.showName;
      data.freeStaffCount = props.freeStaffCount;
      data.type = props.type;
      data.item = props.item;
      data.selected = props.selected;
    });
    const selectTransferItem = () => {
      ctx.emit("selectTransferItem", { item: data.item, type: data.type });
    };
    return {
      ...toRefs(data),
      selectTransferItem,
    };
  },
});
</script>
<style lang="scss" scoped>
.transferItem {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: #333;
  width: 100%;
  cursor: pointer;
  height: 60px;
  margin: 0 10px;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
  border: 1px solid #eee;
}

// .dot {
//   position: absolute;
//   top: 5px;
//   left: 5px;
//   width: 5px;
//   height: 5px;
//   border-radius: 50%;
//   background-color: rgb(28, 201, 83);
//   font-size: 12px;
//   color: white;
//   line-height: 20px;
//   text-align: center;
// }

.selected {
  color: #006eff;
  // border: 1px solid #006eff;
  background: rgba(0, 110, 255, 0.1);
  font-family: PingFangSC-Medium !important;
}
</style>
