<template>
  <div
    style="display: flex"
    :class="[
      message.isRevoked
        ? ''
        : !isReverseMessage(message)
          ? 'messageContainer'
          : 'messageContainerReverse',
    ]"
  >
    <img
      class="messageAvatar"
      :src="
        message.avatar == ''
          ? 'https://qcloudimg.tencent-cloud.cn/raw/9c6b6806f88ee33b3685f0435fe9a8b3.png'
          : message.avatar
      "
      v-if="!message.isRevoked"
    />
    <div style="position: relative;" >
      <div class="messageNick" :class="[ isSelfMessage(message) ? 'messageNickR' : 'messageNickL']" >{{ message.nick ? message.nick : "系统消息" }}</div>
      <div
      class="messageBubble"
      :class="[isSelfMessage(message) ? 'selfMessage' : 'otherMessage']"
      :data-id="message.ID"
    >
      <MessageText
        v-if="message.type === types.MSG_TEXT"
        :data="handleTextMessageShowContext(message)"
      />
      <MessageImage
        v-if="message.type === types.MSG_IMAGE"
        :data="handleImageMessageShowContext(message)"
      />
      <MessageVideo
        v-if="message.type === types.MSG_VIDEO"
        :data="handleVideoMessageShowContext(message)"
      />
      <MessageAudio
        v-if="message.type === types.MSG_AUDIO"
        :data="handleAudioMessageShowContext(message)"
      />
      <MessageFile
        v-if="message.type === types.MSG_FILE"
        :data="handleFileMessageShowContext(message)"
      />
      <MessageCustom v-if="message.type === types.MSG_CUSTOM" :data="message" />
    </div>
  </div>
    </div>

    
  <div
    v-if="isHasQuoteMessage(message)"
    :class="[isSelfMessage(message) ? 'selfQuoteMessage' : 'otherQuoteMessage']"
    @click="jumpQuoteMessage(message)"
  >
    {{ formateQuoteMessage(message) }}
  </div>
</template>

<script>
import TIM from "@tencentcloud/chat";
import { defineComponent, reactive, toRefs, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import {
  handleTextMessageShowContext,
  handleImageMessageShowContext,
  handleVideoMessageShowContext,
  handleAudioMessageShowContext,
  handleFileMessageShowContext,
  getSessionUserShowName,
} from "../../../../utils/index";
import MessageText from "../messages/message-text.vue";
import MessageImage from "../messages/message-image.vue";
import MessageVideo from "../messages/message-video.vue";
import MessageAudio from "../messages/message-audio.vue";
import MessageFile from "../messages/message-file.vue";
import MessageCustom from "../messages/message-custom.vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "ChatComponent",
  components: {
    MessageText,
    MessageImage,
    MessageVideo,
    MessageAudio,
    MessageFile,
    MessageCustom,
  },
  props: {
    message: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      message: null,
      types: TIM.TYPES,
    });
    const store = useStore();
    const activeSession = computed(() => store.state.session.selectedSession);
    const userInfo = computed(() => store.state.userInfo.userInfo);
    watchEffect(() => {
      data.message = props.message;
    });
    const isSelfMessage = (message) => {
      if (message.flow == "out") {
        return true;
      }
      if (message.flow == "in" && message.from == "@TIM#SYSTEM") {
        return true;
      }
      if (message.flow == "in" && message.from == "administrator") {
        return true;
      }
      return false;
    };

    const isHasQuoteMessage = (message) => {
      try {
        if (JSON.parse(message?.cloudCustomData)[0]?.ID) {
          return true;
        }
      } catch (e) {
        return false;
      }
      return false;
    };

    const quoteMessageUserName = (quoteMessage) => {
      return quoteMessage?.flow === "in" &&
        quoteMessage?.from !== "administrator" &&
        quoteMessage?.from === activeSession.value?.userId
        ? getSessionUserShowName(activeSession.value)
        : quoteMessage?.from === userInfo.value?.userId
          ? userInfo.value?.userId
          : quoteMessage?.from;
    };

    const formateQuoteMessage = (originMessage) => {
      const message = JSON.parse(originMessage?.cloudCustomData)[0];
      if (message.isRevoked) {
        ("已撤回的信息");
      }
      if (message.type === data.types.MSG_TEXT) {
        return quoteMessageUserName(message) + ":" + message.payload.text;
      }
      if (message.type === data.types.MSG_IMAGE) {
        return quoteMessageUserName(message) + ":" + "[图片消息]";
      }
      if (message.type === data.types.MSG_VIDEO) {
        return quoteMessageUserName(message) + ":" + "[视频消息]";
      }
      if (message.type === data.types.MSG_AUDIO) {
        return quoteMessageUserName(message) + ":" + "[音频消息]";
      }
      if (message.type === data.types.MSG_FILE) {
        return quoteMessageUserName(message) + ":" + "[文件消息]";
      }
      if (message.type === data.types.MSG_LOCATION) {
        return quoteMessageUserName(message) + ":" + "[位置消息]";
      }
      if (message.type === data.types.MSG_CUSTOM) {
        return quoteMessageUserName(message) + ":" + "[自定义消息]";
      }
    };

    const isReverseMessage = (message) => {
      if (message.flow == "out") {
        return true;
      }
      if (message.flow == "in" && message.from == "@TIM#SYSTEM") {
        return true;
      }
      if (message.flow == "in" && message.from == "administrator") {  // 系统消息
        return true;
      }
      return false;
    };

    const jumpQuoteMessage = (message) => {
      if (isHasQuoteMessage(message)) {
        let element = document.querySelectorAll(
          `[data-id="${JSON.parse(message?.cloudCustomData)[0]?.ID}"]`
        )[0];
        if (element) {
          element.scrollIntoView({ block: "center", inline: "nearest" });
          element.animate(
            [{ opacity: 1 }, { backgroundColor: "#ff9c19" }, { opacity: 1 }],
            1000
          );
        } else {
          ElMessage.info("引用消息不存在");
        }
      }
    };

    return {
      ...toRefs(data),
      handleTextMessageShowContext,
      handleImageMessageShowContext,
      handleVideoMessageShowContext,
      handleAudioMessageShowContext,
      isSelfMessage,
      handleFileMessageShowContext,
      isHasQuoteMessage,
      formateQuoteMessage,
      isReverseMessage,
      jumpQuoteMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
.messageBubble {
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  margin: 0px;
  margin-top: 20px;
  line-height: 18px;
  word-break: break-all;
  background: #abcffc;
  position: relative;
}

.messageContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 30px;
  padding: 0px 20px 10px 20px;
}

.messageContainerReverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin-top: 30px;
  padding: 0px 20px 10px 20px;
}

.selfMessage {
  border-top-right-radius: 0px;
  margin-right: 8px;
}

.otherMessage {
  border-top-left-radius: 0px;
  margin-left: 8px;
}

.messageAvatar {
  width: 36px;
  height: 36px;
}
.messageNick{
  font-size: 12px;
  box-sizing: border-box;
  width: 600px;
  height: 20px;
  overflow: hidden;    
  text-overflow: ellipsis;    
  white-space: nowrap;
}

.messageNickL{
  position: absolute;
  top: 0;
  left: 10px;
}
.messageNickR{
  position: absolute;
  top: 0;
  right: 10px;
  text-align: right;
}

.selfQuoteMessage {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  margin-left: auto;
  margin-right: 60px;
  margin-bottom: 10px;
}

.otherQuoteMessage {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 50px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  margin-right: auto;
  margin-left: 60px;
  margin-bottom: 10px;
}

@-webkit-keyframes reference {
  from {
    opacity: 1;
  }

  50% {
    background-color: #ff9c19;
  }

  to {
    opacity: 1;
  }
}

@keyframes reference {
  from {
    opacity: 1;
  }

  50% {
    background-color: #ff9c19;
  }

  to {
    opacity: 1;
  }
}
</style>
