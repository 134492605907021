<template>
    <div class="loginPage">
        <el-form ref="ruleFormRef" style="max-width: 600px" :model="ruleForm" status-icon :rules="rules"
            label-width="auto" class="demo-ruleForm">
            <el-form-item label="账号/邮箱" prop="username">
                <el-input v-model.number="ruleForm.username" />
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="ruleForm.password" type="password" autocomplete="off" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm(ruleFormRef)">
                    登录
                </el-button>
                <el-button @click="resetForm(ruleFormRef)">清除</el-button>
            </el-form-item>
        </el-form>
    </div>

    <!-- <button @click="setLogin">登录</button> -->
</template>

<script setup>
import { LoginApi } from "../utils/api"
import { useRouter } from 'vue-router'
import { reactive, ref } from 'vue'
import { ElMessageBox } from "element-plus";

// 使用 useRouter 创建一个 router 实例
const router = useRouter()
// const setLogin = () => {
//     let data = {
//         username: "kft001",
//         password: 123456,
//     }
//     LoginApi(data).then((res) => {
//         if (res.code == 0) {
//             sessionStorage.setItem("sdkconfig", JSON.stringify(res.data))
//             router.push({ path: '/home' })
//         }
//     })
// }

const ruleFormRef = ref()

const checkusername = (rule, value, callback) => {
    if (!value) {
        console.log(rule, value, callback)
        return callback(new Error('请输入账号或邮箱'))
    } else {
        callback()
    }
}

const validatePass = (rule, value, callback) => {
    if (value === '') {
        console.log(rule, value, callback)

        callback(new Error('Please input the password'))
    } else {
        callback()
    }
}


const ruleForm = reactive({
    username: 'kft001', //  kft001
    password: "123456", //   123456
})

const rules = reactive({
    username: [{ validator: checkusername, trigger: 'blur' }],
    password: [{ validator: validatePass, trigger: 'blur' }],
})

const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
        console.log(ruleForm)
        if (valid) {
            console.log('submit!')
            let data = ruleForm
            LoginApi(data).then((res) => {
                if (res.code == 0) {
                    sessionStorage.setItem('ruleForm', JSON.stringify(ruleForm))
                    sessionStorage.setItem("sdkconfig", JSON.stringify(res.data))
                    router.push({ path: '/home' })
                } else {
                    ElMessageBox.alert("您的账号密码错误请重新登录", "提示", {
                        confirmButtonText: "确定",
                    });
                }
            }).catch((e)=>{
                console.log(e)
            })


        } else {
            console.log('error submit!')
        }
    })
}

const resetForm = (formEl) => {
    if (!formEl) return
    formEl.resetFields()
}
</script>

<style lang="scss">
.loginPage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>