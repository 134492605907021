<!-- 快捷回复 -->
<template>
    <div style="position: relative;" >
        <el-upload class="upload-demo" drag :show-file-list="false" action="#"
        list-type="picture"
        :http-request="handleFileUpload">
        <!-- <img style="width: 40px;" src="../../../assets/icon/add.png" alt=""> -->
        <el-button type="primary">点击上传</el-button> 
    </el-upload>
     <!-- <el-progress class="upload-progress" v-if="data.progress" :text-inside="true" :stroke-width="20"
            :percentage="progress" />
    <div v-if="data.progress == 100" class="cuo" @click="Remove">×</div> -->
    </div>
</template>

<script setup>
import { reactive, toRefs, defineEmits } from "vue";
import { uploadFile } from "../../../utils/api"

const emit = defineEmits(['changefile',"delRemove"]);
const data = reactive({
    progress: 0,
    name: "9b7178c244b16b22c2f63719f9636db5.zip",
    uid:null,
    url:"",
});
let { uid, name } = toRefs(data)
const handleFileUpload = async (file) => {

    console.log('el-upload 返回的参数 === ', file);
    // 文件信息
    const files = file.file;
    // 从 0 开始的切片
    const shardIndex = 0;
    // 调用 文件切片 方法
    uploadFileSilce(files, shardIndex);
    name.value = files.name
    uid.value = files.uid
    // 文件非法，则进行提示
    emit("changefile", {
        uid: files.uid,
        name: files.name,
        progress: 0,
        url:data.url,
    });
};
/**
 * @description: 分片函数
 * @param file 文件
 * @param shardIndex 分片数量
 */
// const Remove = ()=>{
//     emit("delRemove", data.uid);
// }

const uploadFileSilce = async (file, shardIndex) => {
    // 文件名
    const { name } = file;
    // 文件大小
    const { size } = file;
    // 分片大小
    const shardSize = 1024 * 1024 * 1;
    // 文件加密
    // const hash = await getMD5(file);
    // 分片总数
    const shardTotal = Math.ceil(size / shardSize);
    // 如果 当前分片索引 大于 总分片数
    if (shardIndex >= shardTotal) {
        data.progress = 100;
        // 合并文件
        // composeFile(name, hash);
        emit("changefile", { uid: file.uid,  name: name, progress: 100, url: data.url, });
        return;
    }
    // 文件开始结束的位置
    const start = shardIndex * shardSize;
    const end = Math.min(start + shardSize, size);
    //  开始切割
    const packet = file.slice(start, end);
    // 拼接请求参数
    const formData = {
        token: null,
        id: file.uid,
        name: name,
        type: file.type,
        lastModifiedDate: file.lastModifiedDate,
        size: size,
        chunks: shardTotal,
        chunk: shardIndex,
        file: packet,
    }
    // 如果 当前分片索引 小于 总分片数
    if (shardIndex < shardTotal) {
        // 进度条保留两位小数展示
        data.progress = Number(((shardIndex / shardTotal) * 100).toFixed(2)) * 1;
        emit("changefile", { uid: file.uid, name: name, progress: data.progress, url: data.url, });
        // 调用文件上传接口
        // console.log(formData)
        const res = await uploadFile(formData);
        if (res.code === 1) {
            // 这里为所有切片上传成功后进行的操作
            console.log('上传成功');
            data.progress = 100
            data.url = res.data.url
        }
        // eslint-disable-next-line no-param-reassign
        shardIndex++;
        // 递归调用 分片函数
        uploadFileSilce(file, shardIndex);
    }
};
</script>

<style lang="scss" scoped>
.upload-demo {
    ::v-deep .el-upload .el-upload-dragger {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;width: 100px;
        border: none;
    }



    .upload-progress {
        width: 80px;
    }
}

.upload-name {
    width: 70px;
    height: 40px;
    font-size: 10px;
    line-height: 12px;
}

.name{

    display: -webkit-box;
    /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical;
    /* 垂直排列 */
    -webkit-line-clamp: 3;
    /* 限制显示两行 */
    overflow: hidden;
    /* 隐藏超出范围的内容 */
    text-overflow: ellipsis;
    /* 使用省略号 */

}
.cuo{
    position: absolute;
    top: 0;
    right: 13px;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(50%,-50%);
    border-radius: 50%;
}
</style>