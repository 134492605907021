<template>
  <div class="face" id="face" @click="toggleShow">
    <main class="face-main" v-show="show" ref="dialog">
      <ul
        class="face-list"
        v-for="(item, index) in list"
        :key="index"
        v-show="currentIndex === index"
      >
        <li
          class="face-list-item"
          v-for="(childrenItem, childrenIndex) in item"
          :key="childrenIndex"
          @click="select(childrenItem, childrenIndex)"
        >
          <img :src="emojiPlusBaseUrl + emojiPlusUrlMapping[childrenItem]" />
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { emojiPlusBaseUrl, emojiPlusUrlMapping, emojiName } from "../../../../utils/emojiMap";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "EmojiButton",
  setup(props, ctx) {
    const data = reactive({
      emojiPlusBaseUrl,
      emojiPlusUrlMapping,
      emojiName,
      show: false,
      currentIndex: 0,
    });

    const dialog = ref();
    const toggleShow = () => {
      const main = document.getElementsByClassName("face-main")[0];
      if (main?.style) {
        main.style.display = main?.style?.display === "none" ? "flex" : "none";
      }
    };

    onClickOutside(dialog, () => {
      const main = document.getElementsByClassName("face-main")[0];
      if (main) {
        main.style.display = "none";
      }
    });

    const select = async (item) => {
      const options = {
        name: item,
      };
      options.type = "emo";
      options.url = emojiPlusBaseUrl + emojiPlusUrlMapping[item];
      options.template = `<img src="${emojiPlusBaseUrl + emojiPlusUrlMapping[item]}">`;
      toggleShow();
      return ctx.emit("clickEmoji", options);
    };

    const list = computed(() => {
      const emojiList = [data.emojiName];
      return emojiList;
    });

    return {
      ...toRefs(data),
      toggleShow,
      select,
      list,
      dialog,
    };
  },
});
</script>

<style lang="scss" scoped>
.face-list-item::marker {
  content: none;
}

.face {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/face.png") no-repeat;
  background-size: contain;

  &-main {
    position: absolute;
    z-index: 999;
    width: 435px;
    height: 250px;
    top: -270px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  &-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-left: 10px;

    &-item {
      padding: 5px;
    }

    img {
      width: 30px;
    }

    .face-img {
      width: 60px;
    }
  }
}
</style>
